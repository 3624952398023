import React, { useState } from "react";
import {
  Alert,
  Row,
  Col,
  Label,
  FormGroup,
  Button,
  Input,
  Spinner,
} from "reactstrap";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import FeatherIcon from "feather-icons-react";
import { formatCurrency } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const FormArs = ({ toggle }) => {
  const [loading, setLoading] = useState(false);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [valorArs, setValorArs] = useState("");
  const [cotacao, setCotacao] = useState(null);
  const [ordem, setOrdem] = useState(null);
  const [file, setFile] = useState(null);
  const [bankData, setBankData] = useState(null);

  const cotar = async () => {
    if (!valorArs || valorArs === "0") {
      toast.error("Ingrese un valor válido");
      return;
    }

    setLoading(true);
    const data = {
      amount: parseFloat(valorArs),
      moeda_id: 3, //ARS
    };
    const response = await apiClient.post("/compras/cotacao", data);

    if (response.sucesso) {
      setCotacao(response.dados);
    } else {
      if (response.mensagem) toast.error(response.mensagem);
      setCotacao(null);
    }
    setLoading(false);
  };

  const gerarOrdem = async () => {
    setLoading(true);
    const response = await apiClient.post("/ordem-nautt", {
      moeda: "ARS",
      total: parseFloat(valorArs),
      expiracao: 600,
      deposito: true
    });

    if (response.sucesso) {
      setOrdem(response.dados);
      const bankDataString = [
        `Banco: ${response.dados.banco.banco}`,
        `Nombre: ${response.dados.banco.nome}`,
        `CVU: ${response.dados.banco.conta_cvu}`,
        `Alias: ${response.dados.banco.aba_alias}`,
      ].join("\n");
      setBankData(bankDataString);
    } else {
      if (response.mensagem) toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const handleCopyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("¡Copiado con éxito!");
    } catch (error) {
      toast.error("Error al copiar el texto");
    }
  };

  const enviarComprovante = async (ordem) => {
    setLoadingComponent(true);

    try {
      if (!file) {
        toast.error("Por favor, seleccione un archivo de comprobante.");
        setLoadingComponent(false);
        return;
      }

      const formData = new FormData();
      formData.append("comprovante", file);
      formData.append("ordem_id", ordem.id);

      const extensaoComprovante = file.name.split(".").pop();
      formData.append("extensaoComprovante", extensaoComprovante);
      formData.append("uuid", ordem.uuid);

      const response = await apiClient.post(
        "/ordens-nautt/comprovante",
        formData
      );

      if (response.sucesso) {
        toast.success("¡Comprobante enviado con éxito! Procesaremos su depósito en breve.");
        toggle();
      } else {
        toast.error(response.mensagem || "Error al subir el comprobante");
      }
    } catch (error) {
      console.error("Error al enviar comprobante:", error);
      toast.error("Ocurrió un error al enviar el comprobante");
    } finally {
      setLoadingComponent(false);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validTypes = [
        "application/pdf",
        "image/png",
        "image/jpeg",
        "image/jpg",
      ];
      if (validTypes.includes(fileType)) {
        setFile(selectedFile);
      } else {
        toast.error("Por favor, seleccione un archivo PDF, PNG o JPG/JPEG");
        e.target.value = null;
      }
    }
  };

  if (ordem) {
    return (
      <div className="mt-4 p-4">
        <div className="text-center">
          <b>¡Orden de pago generada con éxito!</b>
          <p>
            Asegúrese de transferir el monto exacto a la cuenta informada y
            permanezca en esta pantalla para recibir la confirmación:
          </p>
          <Alert color="success" className="m-1">
            <div className="text-left">
              {bankData?.split("\n").map((line, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-start mt-2"
                >
                  <div className="me-2">{line}</div>
                  <Button
                    color="link"
                    className="p-0"
                    onClick={() => handleCopyToClipboard(line)}
                  >
                    <FeatherIcon icon="copy" size={16} />
                  </Button>
                </div>
              ))}
              <div className="d-flex align-items-center justify-content-start mt-3">
                <div className="me-2">
                  Valor: {formatCurrency(valorArs, "ARS")}
                </div>
                <Button
                  color="link"
                  className="p-0"
                  onClick={() => handleCopyToClipboard(`${valorArs}`)}
                >
                  <FeatherIcon icon="copy" size={16} />
                </Button>
              </div>
            </div>
          </Alert>
          <Alert color="warning" className="mt-3">
            <div className="text-center">
              <p className="mb-2">
                <strong>¡Importante!</strong> Después de realizar el pago, suba
                el comprobante:
              </p>
              <p className="small text-muted mb-3">
                Archivos permitidos: PDF, PNG, JPG o JPEG
              </p>
              <div className="d-flex justify-content-center align-items-center gap-2">
                <Input
                  type="file"
                  onChange={handleFileChange}
                  accept=".pdf,.png,.jpg,.jpeg"
                  className="form-control"
                  style={{ maxWidth: "300px" }}
                />
                <Button
                  color="success"
                  onClick={() => enviarComprovante(ordem)}
                  disabled={!file || loadingComponent}
                >
                  {loadingComponent ? (
                    <Spinner size="sm" />
                  ) : (
                    <FeatherIcon icon="upload" size={16} className="me-1" />
                  )}
                </Button>
              </div>
            </div>
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label className="form-label">Token</Label>
            <div className="form-control-plaintext">
              <strong>USDT Arbitrum</strong>
            </div>
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <Label className="form-label">Valor en ARS</Label>
            <NumericFormat
              value={valorArs}
              onValueChange={(values) => {
                setValorArs(values.value);
                setCotacao(null);
              }}
              thousandSeparator="."
              decimalSeparator=","
              prefix="ARS "
              placeholder="0,00"
              className="form-control"
            />
          </FormGroup>
        </Col>
      </Row>

      {!cotacao && (
        <div className="text-center mt-4">
          <Button
            color="primary"
            onClick={cotar}
            disabled={!valorArs || loading}
          >
            {loading ? (
              <i className="bx bx-loader bx-spin me-1" />
            ) : (
              <i className="ri-exchange-dollar-line me-1" />
            )}
            Cotizar
          </Button>
        </div>
      )}

      {cotacao && (
        <>
          <Alert color="info" className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <strong>Cotización USDT/ARS:</strong>{" "}
                {formatCurrency(cotacao.cotacao, "ARS")}
              </div>
              <div>
                <strong>Total en USDT:</strong>{" "}
                {formatCurrency(cotacao.total_moeda, "USD")}
              </div>
            </div>
          </Alert>

          <div className="text-center mt-4">
            <Button color="success" disabled={loading} onClick={gerarOrdem}>
              {loading && <i className="bx bx-loader bx-spin me-2" />}
              Generar Orden de Pago
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default FormArs;
